import $ from 'jquery'

// Toggle using prefix
//   Can be used with any input or select. Although might only work properly with radio inputs + selects
//   Although, there are now obvious differences with the implementation of data-radio-toggle-prefix, I am not sure why or if that was necessary.
//
//   if toggle element have sub toggle controller(`togglePrefixSelector`) that should be checked ->
//   add to it class ${prefix}Cascade-${val}

const togglePrefixSelector =
  'input[data-toggle-prefix],select[data-toggle-prefix]'

$(document).on(
  'change.toggle-prefix toggle-prefix',
  'input[data-toggle-prefix],select[data-toggle-prefix]',
  function (e, data) {
    const $this = $(this)
    if ($this.is(':not(:radio)') || $this.is(':checked')) {
      $this
        .data('toggle-prefix')
        .split(' ')
        .forEach((p) => {
          const prefix = p.trim()
          if (!prefix) return
          const val =
            $this.attr('type') == 'checkbox'
              ? $this.is(':checked')
              : $this.val()
          const isAjaxUpdating = window.Simplero?.state?.isAjaxUpdating
          const animate =
            ($this.data('toggle-prefix-animate') ?? true) && !isAjaxUpdating

          $(
            `[class*=${prefix}-][class!=${prefix}-${val}]:not(.${prefix}-${val}), [class*=${prefix}OnlyHide-][class!=${prefix}OnlyHide-${val}]:not(.${prefix}-${val})`
          ).hideInputs(animate)

          if (window.js_dom_ready && (data?.cascade || !isAjaxUpdating)) {
            $(`.${prefix}-${val}`).showInputs(animate)
          }

          $(`.${prefix}Cascade-${val}`)
            .find(togglePrefixSelector)
            .trigger('toggle-prefix', { cascade: true })
        })
    }
  }
)

if (window.onDomChanged) {
  window.onDomChanged(togglePrefixSelector, 1000, function () {
    return this.trigger('toggle-prefix')
  })
} else {
  $(function () {
    $(togglePrefixSelector).trigger('toggle-prefix')
  })
}
